import Bowser from 'bowser'

export const isServer = () => typeof window === 'undefined'
export const isClient = () => !isServer()

export const getIsMobileOrTablet = (req = null) => {
  const bowser =
    isServer() && req
      ? Bowser.getParser(req.headers['user-agent'] ?? '')
      : isClient()
        ? Bowser.getParser(window.navigator.userAgent)
        : null
  if (bowser) {
    const platformType = bowser.getPlatformType()
    return platformType === 'mobile' || platformType === 'tablet'
  }
  return false
}
